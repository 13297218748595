var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'input-container',
    {'valid':_vm.invalid === false},
    {'error':_vm.invalid === true} ]},[(_vm.$slots['label'] || _vm.label.length>0)?_c('div',{staticClass:"input-label"},[_vm._t("label",[(_vm.label.length>0)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e()])],2):_vm._e(),_c('div',{class:[
            'tag-input',
            'form-control',
            'input',
            {'focused':_vm.inputFocused},
            {'valid':_vm.invalid === false},
            {'error':_vm.invalid === true}
        ],on:{"click":_vm.focusInput}},[_vm._l((_vm.tags),function(tag,i){return _c('span',{key:_vm.key(i),class:['tag', ("tag-" + _vm.type)]},[_c('span',{staticClass:"tag-text"},[_vm._v(_vm._s(tag))]),_c('span',{ref:"close",refInFor:true,staticClass:"tag-close",domProps:{"innerHTML":_vm._s(_vm.closeIcon)},on:{"click":function($event){return _vm.remove($event, i)}}})])}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputText),expression:"inputText"}],ref:"input",staticClass:"input",attrs:{"placeholder":_vm.showPlaceholder?_vm.placeholder:'',"type":"text"},domProps:{"value":(_vm.inputText)},on:{"focus":function($event){_vm.inputFocused=true},"blur":function($event){_vm.inputFocused=false},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.add($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.removeLast($event)}],"input":function($event){if($event.target.composing){ return; }_vm.inputText=$event.target.value}}})],2),(_vm.$slots['info'] || _vm.infoText.length>0)?_c('div',{staticClass:"input-info"},[_vm._t("info",[(_vm.infoText.length > 0)?_c('span',[_vm._v(_vm._s(_vm.infoText))]):_vm._e()])],2):_vm._e(),(_vm.$slots['error'] || _vm.errorText.length>0)?_c('div',{staticClass:"input-error"},[_vm._t("error",[(_vm.errorText.length > 0)?_c('span',[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }