var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('Select',{staticClass:"mb-3",attrs:{"data":_vm.taskTypes},on:{"change":function($event){_vm.selectedTaskType = $event}},scopedSlots:_vm._u([{key:"placeholder",fn:function(ref){
var selectedItem = ref.selectedItem;
return [(selectedItem !== null && selectedItem !== undefined)?_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_vm._v(_vm._s(selectedItem.name))])]):_vm._e()]}},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-items-center text-"},[_c('div',{staticClass:"w-75"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}])}),(_vm.selectedTaskType)?_c('Input',{attrs:{"placeholder":"","type":"number","label":"Очки к начислению","invalid":_vm.validating ? _vm.points.length == 0 : null,"error-text":_vm.validating && _vm.points.length == 0 ? 'Поле не заполнено' : ''},model:{value:(_vm.points),callback:function ($$v) {_vm.points=$$v},expression:"points"}}):_vm._e(),(
      _vm.selectedTaskType &&
        (_vm.isVideoTask(_vm.selectedTaskType.id) ||
          _vm.isLongreadTask(_vm.selectedTaskType.id))
    )?_c('Input',{attrs:{"placeholder":"","label":"Ссылка","invalid":_vm.validating ? _vm.url.length == 0 : null,"error-text":_vm.validating && _vm.url.length == 0 ? 'Поле не заполнено' : ''},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}):_vm._e(),(
      _vm.selectedTaskType &&
        (_vm.isVideoTask(_vm.selectedTaskType.id) ||
          _vm.isLongreadTask(_vm.selectedTaskType.id))
    )?_c('Input',{attrs:{"placeholder":"","type":"number","label":"Время на изучение (минуты)","invalid":_vm.validating ? _vm.time_to_study.length == 0 : null,"error-text":_vm.validating && _vm.time_to_study.length == 0 ? 'Поле не заполнено' : ''},model:{value:(_vm.time_to_study),callback:function ($$v) {_vm.time_to_study=$$v},expression:"time_to_study"}}):_vm._e(),(_vm.selectedTaskType && (_vm.isMainTask(_vm.selectedTaskType.id) || _vm.isAdditionalTask(_vm.selectedTaskType.id)))?_c('div',_vm._l((_vm.questions),function(quest,qindex){return _c('div',{key:qindex},[_c('div',{staticClass:"input-label mt-3 mb-1"},[(_vm.isMainTask(_vm.selectedTaskType.id))?_c('b',[_vm._v(_vm._s(+qindex + 1)+") Вопрос:")]):_c('b',[_vm._v("Вопрос:")])]),_c('Input',{staticClass:"mt-1 mb-1",attrs:{"tag":"textarea","rows":"6","invalid":_vm.validating ? quest.qtext.length == 0 : null,"error-text":_vm.validating && quest.qtext.length == 0 ? 'Поле не заполнено' : ''},model:{value:(quest.qtext),callback:function ($$v) {_vm.$set(quest, "qtext", $$v)},expression:"quest.qtext"}}),_c('div',[_vm._m(1,true),_c('Dropzone',{ref:"dropzone",refInFor:true,staticClass:"mb-3",attrs:{"multiple":true},on:{"change":function($event){return _vm.encodeImages(quest, $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var files = ref.files;
    var removeFileByIndex = ref.removeFileByIndex;
    var active = ref.active;
    var browse = ref.browse;
return undefined}}],null,true)})],1),(quest.images && quest.images.length > 0)?_c('div',[_c('div',{staticClass:"mt-4"},[_vm._v(" Добавленные файлы: ")]),_vm._l((quest.images),function(file){return _c('div',{key:file._id},[_c('div',{staticClass:"input-label"},[_c('label',[_vm._v(_vm._s(file.name))])]),_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":'data:' + file.contentType + ';base64,' + (file.src ? file.src : file.data)}})])})],2):_vm._e(),_vm._m(2,true),_c('Select',{staticClass:"mb-3",attrs:{"data":_vm.taskAnswerTypes,"invalid":_vm.validating ? _vm.getAnswerType(quest) === undefined : null,"error-text":_vm.validating && _vm.getAnswerType(quest) === undefined ? 'Поле не заполнено' : ''},on:{"change":function($event){return _vm.changeAnswerType(quest, $event)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(ref){
    var selectedItem = ref.selectedItem;
return [(selectedItem !== null && selectedItem !== undefined)?_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',[_vm._v(_vm._s(selectedItem.name))])]):_vm._e()]}},{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-items-center text-"},[_c('div',{staticClass:"w-75"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}],null,true)}),(quest.type !== undefined && quest.type !== null)?_c('div',[_vm._m(3,true),_c('div',{class:[
            {
              'input-container error border rounded border-danger p-2': _vm.validating && _vm.isTypeOne(quest) && (_vm.hasRightAnswer(quest) === false)
            }
          ]},_vm._l((quest.answer),function(answer,aindex){return _c('div',{key:aindex},[_c('Input',{staticClass:"mb-1",attrs:{"invalid":_vm.validating ? answer.atext.length == 0 : null,"error-text":_vm.validating && answer.atext.length == 0
                  ? 'Поле не заполнено'
                  : ''},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.isTypeOne(quest))?_c('div',{staticClass:"input-group-text",class:[{'bg-success': answer.isRight}],staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changeAnswerRight(answer)}}},[_c('span',{class:[{'text-light': answer.isRight}]},[_vm._v(_vm._s(aindex + 1)+".")])]):(_vm.isTypeSequence(quest))?_c('div',{staticClass:"input-group-text bg-light"},[_c('span',[_vm._v(_vm._s(aindex + 1)+".")])]):_vm._e()]},proxy:true}],null,true),model:{value:(answer.atext),callback:function ($$v) {_vm.$set(answer, "atext", $$v)},expression:"answer.atext"}})],1)}),0)]):_vm._e(),(_vm.validating && _vm.isTypeOne(quest) && (_vm.hasRightAnswer(quest) === false))?_c('div',{staticClass:"input-error"},[_c('span',[_vm._v("Не выбран правильный ответ")])]):_vm._e(),(_vm.isTypeSequence(quest))?_c('TagInput',{attrs:{"info-text":"Впишите номер ответа и нажмите Enter","label":"Последовательность правильных ответов","invalid":_vm.validating ? (_vm.getSequenceValidateError(quest)).length > 0 : null,"error-text":_vm.validating ? _vm.getSequenceValidateError(quest) : ''},model:{value:(quest.right_answer_sequence),callback:function ($$v) {_vm.$set(quest, "right_answer_sequence", $$v)},expression:"quest.right_answer_sequence"}}):_vm._e()],1)}),0):_vm._e(),_c('button',{ref:"saveTask",staticClass:"btn btn-success mt-3",on:{"click":_vm.saveTask}},[_vm._v(" Сохранить ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-label mb-3"},[_c('span',[_vm._v("Тип задания")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-label"},[_c('label',[_vm._v("Прикрепленные к вопросу изображения")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-label mb-3"},[_c('span',[_vm._v("Тип ответа на вопрос:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-label mt-2 mb-1"},[_c('b',[_vm._v("Ответы:")])])}]

export { render, staticRenderFns }