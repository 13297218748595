import Vue from 'vue';
import App from './App.vue';
import axios from "axios";

// Loading application components and styles
import loader from './plugins/loader';
Vue.use(loader);
import router from './router';

const base = axios.create({
//  baseURL: "http://localhost:4000/api",
  baseURL: "https://bot.professorsneakers.ru/api",
  headers: {
    "Content-type": "application/json; charset=UTF-8"
  }
});

Vue.prototype.$http = base;
Vue.config.productionTip = false;
window.app = new Vue({
  render: h => h(App),
  router,
}).$mount('#app');

if (localStorage.getItem("jwt") != null) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("jwt");
}
Vue.prototype.$http.interceptors.response.use(function (response) {
  // Do something with response datax
  return response;
}, function (error) {
  // Do something with response error
  if (error.message === 'Request failed with status code 401') {
    localStorage.removeItem("jwt");
    document.location = document.location.origin + '/login';
  }

  return Promise.reject(error);
});
