var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'input-container',
    {'valid':_vm.invalid === false},
    {'error':_vm.invalid === true} ]},[(_vm.$slots['label'] || _vm.label.length>0)?_c('div',{staticClass:"input-label"},[_vm._t("label",[(_vm.label.length>0)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e()])],2):_vm._e(),_c('div',{staticClass:"input-group"},[_vm._t("prepend"),_c(_vm.tag,_vm._g({tag:"component",class:[
                'form-control',
                'input',
                {'valid':_vm.invalid === false},
                {'error':_vm.invalid === true},
                {'form-control-lg': _vm.size === 'lg'},
                {'form-control-sm': _vm.size === 'sm'} ],attrs:{"type":_vm.type,"name":_vm.name,"placeholder":_vm.placeholder,"value":_vm.value,"rows":_vm.rows,"disabled":_vm.disabled}},_vm.events),[(_vm.tag==='textarea')?[_vm._v(_vm._s(_vm.value))]:_vm._e()],2),_vm._t("append")],2),(_vm.$slots['info'] || _vm.infoText.length>0)?_c('div',{staticClass:"input-info"},[_vm._t("info",[(_vm.infoText.length > 0)?_c('span',[_vm._v(_vm._s(_vm.infoText))]):_vm._e()])],2):_vm._e(),(_vm.$slots['error'] || _vm.errorText.length>0)?_c('div',{staticClass:"input-error"},[_vm._t("error",[(_vm.errorText.length > 0)?_c('span',[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }