<template>
    <div class="accordion" >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Accordion",

        props:{
            alwaysOne:{
                type:Boolean,
                default:false,
                description: 'If always-one property is added as true then only one accordion item will be open at a time. Others will be closed when new is enabled.'
            },
            scrollToSelected:{
                type:Boolean,
                default:true,
                description: 'Scroll to selected item'
            }
        },

        data(){
            return{
                children:[],
            };
        },

        methods:{
            // Close other accordion-items
            async closeOthers(currentChild, isCollapseOpen){
                if(isCollapseOpen){
                    for await (const child of this.$children) {
                        if(currentChild !== child && isCollapseOpen){
                            child.close();
                        }
                    }
                }
            },

            async scrollTo(currentChild, isCollapseOpen) {
                if (isCollapseOpen) {
                    for await (const child of this.$children) {
                        if(currentChild === child && isCollapseOpen){
                            setTimeout(function () {
                                child.$el.scrollIntoView({ behavior: 'smooth' });
                            }, 222);
                        }
                    };
                }
            }
        },

        async mounted() {
            // Listen for changes and close other accordion-items if needed
            this.$children.forEach((child) => {
                if(this.alwaysOne && this.scrollToSelected) {
                    child.$on('change', async (state)=>{
                        await this.closeOthers(child, state);
                        await this.scrollTo(child, state);
                    });
                } else if (!this.alwaysOne && this.scrollToSelected) {
                    child.$on('change', async (state)=>{
                        await this.scrollTo(child, state);
                    });
                }
            });
        }
    }
</script>

<style scoped>

</style>