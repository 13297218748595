var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'select-dropdown',
    'input-container', 
    {'menu-open':!_vm.dropdownStateClosed},
    {'valid':_vm.invalid === false},
    {'error':_vm.invalid === true}
]},[_c('Dropdown',{ref:"dropdown",class:[
                'w-100'
            ],attrs:{"no-animation":"","y-offset":"3","menu-classes":"w-100 py-0"}},[_c('div',{class:[
                    'select-dropdown-placeholder',
                    'h-auto',
                    'form-control',
                    'input',
                    'w-100',
                    'd-flex',
                    'align-items-center',
                    'justify-content-between',
                    {'valid':_vm.invalid === false},
                    {'error':_vm.invalid === true}
                ],attrs:{"slot":"button","tabindex":"-1"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.arrowUpEvent($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.arrowDownEvent($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectSilently($event)}],"click":_vm.buttonClick},slot:"button"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFilterInput),expression:"!showFilterInput"}]},[_vm._t("placeholder",[_vm._v(_vm._s(_vm.selectedItem))],{"selectedItem":_vm.selectedItem})],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"},{name:"show",rawName:"v-show",value:(_vm.showFilterInput),expression:"showFilterInput"}],ref:"input",staticClass:"input blank w-100",domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value}}}),_vm._t("handle",[_c('div',{staticClass:"select-dropdown-handle",domProps:{"innerHTML":_vm._s(_vm.icons.ChevronDown)}})],{"dropdownOpen":!_vm.dropdownStateClosed})],2),_c('div',{ref:"items"},_vm._l((_vm.filteredData),function(item,i){return _c('div',{class:['dropdown-item', {'active': item === _vm.silentlySelectedItem}],on:{"click":function($event){return _vm.change(item)},"mouseover":function($event){_vm.silentlySelectedItem = item}}},[_vm._t("default",[_vm._v(_vm._s(item))],{"item":item,"isSelected":item === _vm.selectedItem})],2)}),0)]),(_vm.$slots['error'] || _vm.errorText.length>0)?_c('div',{staticClass:"input-error"},[_vm._t("error",[(_vm.errorText.length > 0)?_c('span',[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }