<template>
  <div>
    <Input
      v-model="task.point"
      placeholder=""
      type="number"
      label="Очки к начислению"
      :invalid="validating ? task.point.length == 0 : null"
      :error-text="
        validating && task.point.length == 0 ? 'Поле не заполнено' : ''
      "
    />

    <Input
      v-if="
        (isVideoTask(task.type) ||
          isLongreadTask(task.type))
      "
      v-model="task.url"
      placeholder=""
      label="Ссылка"
      :invalid="validating ? task.url.length == 0 : null"
      :error-text="
        validating && task.url.length == 0 ? 'Поле не заполнено' : ''
      "
    />

    <Input
      v-if="
        (isVideoTask(task.type) ||
          isLongreadTask(task.type))
      "
      v-model="task.time_to_study"
      placeholder=""
      type="number"
      label="Время на изучение (минуты)"
      :invalid="validating ? task.time_to_study.length == 0 : null"
      :error-text="
        validating && task.time_to_study.length == 0 ? 'Поле не заполнено' : ''
      "
    />

    <div v-if="isMainTask(task.type) || isAdditionalTask(task.type)">
      <div
        v-for="(quest, qindex) in task.question"
        :key="qindex"
      >
        <div class="input-label mt-3 mb-1">
          <b v-if="isAdditionalTask(task.type)">Вопрос:</b>
          <b v-else>{{ +qindex + 1 }}) Вопрос:</b>
        </div>
        <Input
          v-model="quest.qtext"
          tag="textarea"
          rows="6"
          class="mt-1 mb-1"
          :invalid="validating ? quest.qtext.length == 0 : null"
          :error-text="
            validating && quest.qtext.length == 0 ? 'Поле не заполнено' : ''
          "
        />

        <div>
          <div class="input-label">
            <label>Прикрепленные к вопросу изображения</label>
          </div>

          <Dropzone
            v-slot:default="{ files , removeFileByIndex, active, browse }"
            ref="dropzone"
            :multiple="true"
            class="mb-3"
            @change="encodeImages(quest, $event)"
          />
        </div>

        <div 
          v-if="quest.images && quest.images.length > 0"
          class="mb-3"
        >
          <div class="mt-4">
            Добавленные файлы:
          </div>
          <div
            v-for="file in quest.images"
            :key="file._id"
          >
            <div class="input-label mt-1">
              <label>Название файла: {{ file.name }}</label>
            </div>
            <img
              style="max-width: 100px;"
              :src="'data:' + file.contentType + ';base64,' + (file.src ? file.src : file.data)"
            >
            <a 
              class="danger"
              style="cursor: pointer;"
              @click="deleteQuestImage(quest, file)"
            >
              Удалить
            </a>
          </div>
        </div>

        <div 
          class="input-label mb-3"
        >
          <span>Тип ответа на вопрос:</span>
        </div>
        <Select
          class="mb-3"
          :selected="getAnswerType(quest)"
          :data="taskAnswerTypes"
          :invalid="validating ? getAnswerType(quest) === undefined : null"
          :error-text="
            validating && getAnswerType(quest) === undefined ? 'Поле не заполнено' : ''
          "
          @change="changeAnswerType(quest, $event)"
        >
          <template v-slot:placeholder="{ selectedItem }">
            <div
              v-if="selectedItem !== null && selectedItem !== undefined"
              class="d-flex flex-row align-items-center"
            >
              <div>{{ selectedItem.name }}</div>
            </div>
          </template>
          <template v-slot:default="{ item }">
            <div class="d-flex flex-row align-items-center text-">
              <div class="w-75">
                {{ item.name }}
              </div>
            </div>
          </template>
        </Select>

        <div v-if="quest.type !== undefined && quest.type !== null">
          <div class="input-label mt-2 mb-1">
            <b>Ответы:</b>
          </div>
          <div 
            :class="[
              {
                'input-container error border rounded border-danger p-2': validating && isTypeOne(quest) && (hasRightAnswer(quest) === false)
              }
            ]"
          >
            <div
              v-for="(answer, aindex) in quest.answer"
              :key="aindex"
            >
              <Input
                v-model="answer.atext"
                class="mb-1"
                :invalid="validating ? answer.atext.length == 0 : null"
                :error-text="
                  validating && answer.atext.length == 0
                    ? 'Поле не заполнено'
                    : ''
                "
              >
                <template v-slot:prepend>
                  <div
                    v-if="isTypeOne(quest)"
                    style="cursor: pointer;"
                    class="input-group-text"
                    :class="[{'bg-success': answer.isRight}]"
                    @click="changeAnswerRight(answer)"
                  >
                    <span :class="[{'text-light': answer.isRight}]">{{ aindex + 1 }}.</span>
                  </div>
                  <div
                    v-else-if="isTypeSequence(quest)"
                    class="input-group-text bg-light"
                  >
                    <span>{{ aindex + 1 }}.</span>
                  </div>
                </template>
              </Input>
            </div>
          </div>
        
          <div 
            v-if="validating && isTypeOne(quest) && (hasRightAnswer(quest) === false)" 
            class="input-error"
          >
            <span>Не выбран правильный ответ</span>
          </div>

          <TagInput
            v-if="isTypeSequence(quest)"
            v-model="quest.right_answer_sequence"
            info-text="Впишите номер ответа и нажмите Enter"
            label="Последовательность правильных ответов"
            :invalid="validating ? (getSequenceValidateError(quest)).length > 0 : null"
            :error-text="validating ? getSequenceValidateError(quest) : ''"
          />
        </div>
      </div>
    </div>
    <button
      class="btn btn-outline-light-bg-success mt-3"
      @click="updateTask(task)"
    >
      Обновить
    </button>
    <button
      class="btn btn-outline-light-bg-danger mx-2 mt-3"
      @click="deleteTaskDialog(task)"
    >
      Удалить
    </button>

    <Modal
      :open="deleteTaskModal"
      @closed="deleteTaskModal = false"
    >
      <div class="modal-header">
        <h5 class="modal-title m-0">
          Удаление задания
        </h5>
        <button
          type="button"
          class="btn-close outline-none"
          data-dismiss="modal"
          aria-label="Close"
          @click="deleteTaskModal = false"
        />
      </div>
      <div class="modal-body">
        <p>Вы действительно хотите удалить задание?</p>
      </div>
      <div class="modal-footer border-0">
        <button
          type="button"
          class="btn btn-success"
          @click="deleteTask()"
        >
          Да
        </button>
        <button
          type="button"
          class="btn btn-danger"
          data-dismiss="modal"
          @click="deleteTaskModal = false"
        >
          Отмена
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import TaskService from "../../services/TaskService";
import { requiredValidate } from "../../helpers/helpers";
import swal from "sweetalert";
import { encodeImages, hasRightAnswer, isTypeOne, isTypeSequence, getSequenceValidateError } from '../../helpers/taskHelper';

export default {
  name: "EditTask",
  props:{
    task: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      deletingTask: null,
      deleteTaskModal: false,
      validating: false,
      taskAnswerTypes: TaskService.getTaskAnswerTypes(),
    }
  },

  methods: {
    deleteTaskDialog(task) {
      this.deletingTask = task;
      this.deleteTaskModal = true;
    },

    deleteTask() {
      const vm = this;
      this.deleteTaskModal = false;

      TaskService.delete(this.deletingTask._id).then(response => {
        vm.$emit('delete', vm.deletingTask);
        vm.deletingTask = null;
        swal("Успех!", "Задание удалено", "success");
      }).catch(error => {
        console.log(error);
        let err_message = "Что то пошло не так";
        if (error.response && error.response.data.message) {
            err_message = error.response.data.message;
        }
        swal("Ошибка!", err_message, "error");
      });
    },

    async updateTask(task, quite = false) {
      this.validating = true;
      await this.$nextTick();
      if (this.requiredValidate()) {
          return false;
      }

      TaskService.update(task._id, task).then(response => {
        if (quite === false) {
          swal("Успех!", "Задание обновлено", "success");
        }
      }).catch(error => {
        console.log(error);
        let err_message = "Что то пошло не так";
        if (error.response && error.response.data.message) {
            err_message = error.response.data.message;
        }
        swal("Ошибка!", err_message, "error");
      });

      this.validating = false;
    },

    changeAnswerRight(answer) {
      let isRight = answer.isRight ? !answer.isRight : true; 
      this.$set(answer, 'isRight', isRight);
    },
    changeAnswerType(question, event) {
      let vm = this;
      question.type = event.id;
      vm.$forceUpdate();
    },
    getAnswerType(question) {
      const type = this.taskAnswerTypes.find((task) => parseInt(task.id) === parseInt(question.type));
      return type;
    },
    deleteQuestImage(question, file) {
      question.images = question.images.filter((image) => image._id !== file._id);
      this.$forceUpdate();
    },
    requiredValidate: function () {
      const elements = document.getElementsByClassName('input-container error');
      return requiredValidate(elements);
    },
    isAdditionalTask(value) {return TaskService.isAdditionalTask(value);},
    isMainTask(value) {return TaskService.isMainTask(value);},
    isVideoTask(value) {return TaskService.isVideoTask(value);},
    isLongreadTask(value) {return TaskService.isLongreadTask(value);},
    getSequenceValidateError(question) { return getSequenceValidateError(question); },
    hasRightAnswer(question) { return hasRightAnswer(question); },
    isTypeOne(question) { return isTypeOne(question); },
    isTypeSequence(question) { return isTypeSequence(question); },
    encodeImages(question, event) { encodeImages(question, event); },
  }
}
</script>