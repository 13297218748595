import Vue from 'vue';

class TaskService {
    constructor() {
      this.TYPE_MAIN = 0;
      this.TYPE_ADDITIONAL = 1;
      this.TYPE_VIDEO = 2;
      this.TYPE_LONGREAD = 3;

      this.ANSWER_TYPE_ONE = 0;
      this.ANSWER_TYPE_SEQUENCE = 1;

      this.NUM_ANSWERS = 4;
      this.ANSWER_SEQUENCE_NUMS = [1, 2, 3, 4];

      this.taskTypes = [
        {id: this.TYPE_MAIN, name: "Тест(основной)"},
        {id: this.TYPE_ADDITIONAL, name: "Тест(доп. задания)"},
        {id: this.TYPE_VIDEO, name: "Теория видео"},
        {id: this.TYPE_LONGREAD, name: "Теория лонгрид"},
      ];

      this.taskAnswerTypes = [
        {id: this.ANSWER_TYPE_ONE, name: "Один ответ"},
        {id: this.ANSWER_TYPE_SEQUENCE, name: "Последовательность"},
      ];
    }

    async getAll() {
        try {
            return (await Vue.prototype.$http.get("/task")).data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
  
    async get(id) {
      try {
        return (await Vue.prototype.$http.get(`/task/${id}`)).data;
      } catch (error) {
        console.error(error);
        return {};
      }
    }
  
    create(data) {
      return Vue.prototype.$http.post("/task", data);
    }
  
    update(id, data) {
      return Vue.prototype.$http.put(`/task/${id}`, data);
    }

    delete(id) {
      return Vue.prototype.$http.delete(`/task/${id}`);
    }

    getTaskType() {
      return this.taskTypes;
    }

    getTaskAnswerTypes() {
      return this.taskAnswerTypes;
    }

    getTaskTypeName(type) {
      const taskType = this.taskTypes.find(item => {
          return type === item.id;
      });

      return taskType ? taskType.name : '';
    }

    isAdditionalTask(value) {
      return parseInt(value) === this.TYPE_ADDITIONAL;
    }

    isMainTask(value) {
      return parseInt(value) === this.TYPE_MAIN;
    }

    isVideoTask(value) {
      return parseInt(value) === this.TYPE_VIDEO;
    }

    isLongreadTask(value) {
      return parseInt(value) === this.TYPE_LONGREAD;
    }
  }
  
  export default new TaskService();