import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/*jshint -W024 */
const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('./views/Users/List.vue'),
      meta: {
        requiresAuth: true,
        layout: 'main'
      }
    },
    {
      path: '/courses',
      component: () => import('./views/Courses/List.vue'),
      meta: {
        requiresAuth: true,
        layout: 'main'
      }
    },
    {
      path: '/courses/new',
      component: () => import('./views/Courses/New.vue'),
      meta: {
        requiresAuth: true,
        layout: 'main'
      }
    },
    {
      path: '/courses/:id',
      component: () => import('./views/Courses/Edit.vue'),
      meta: {
        requiresAuth: true,
        layout: 'main'
      }
    },
    {
      path: '/days',
      component: () => import('./views/Days/List.vue'),
      meta: {
        requiresAuth: true,
        layout: 'main'
      }
    },
    {
      path: '/days/new',
      component: () => import('./views/Days/New.vue'),
      meta: {
        requiresAuth: true,
        layout: 'main'
      }
    },
    {
      path: '/days/:id',
      component: () => import('./views/Days/Edit.vue'),
      meta: {
        requiresAuth: true,
        layout: 'main'
      }
    },
    {
      path: '/login',
      component: () => import('./views/Login.vue'),
      meta: {
        title: 'Авторизация', 
        layout: 'empty'
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;