var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"main-layout"}},[_c('ToastNotificationsContainer'),(_vm.isLogged)?_c('Sidebar',[_c('div',{staticClass:"sidebar-brand d-flex justify-content-center align-items-center"},[_c('b',{staticClass:"font-size-70 logo-text"},[_vm._v("Панель управления чат-бот Ученый")])]),_c('ul',{staticClass:"nav flex-column sidebar-items"},_vm._l((_vm.links),function(link,i){return _c('li',{key:i,class:['nav-item', 'sidebar-item']},[(link.children === undefined)?_c('span',{class:[
            'sidebar-link d-flex flex-row align-items-center w-100 h-100',
            { active: _vm.$route.path === link.path } ],on:{"click":function($event){return _vm.navigateToPath(link.path, $event)}}},[(link.icon !== undefined)?_c('div',{domProps:{"innerHTML":_vm._s(link.icon)}}):_vm._e(),_c('div',{staticClass:"ms-3"},[_vm._v(_vm._s(link.name))])]):_c('div',[_c('span',{directives:[{name:"collapse",rawName:"v-collapse",value:(link.collapseId),expression:"link.collapseId"}],class:[
              'sidebar-link d-flex flex-row align-items-center w-100 h-100',
              { active: _vm.$route.path === link.path } ],on:{"click":function($event){return _vm.navigateToPath(link.path, $event)}}},[(link.icon !== undefined)?_c('div',{domProps:{"innerHTML":_vm._s(link.icon)}}):_vm._e(),_c('div',{staticClass:"ms-3 d-flex align-items-center w-100"},[_c('div',{staticClass:"w-100"},[_vm._v(_vm._s(link.name))]),_c('svg',{staticClass:"stroke-default ms-2 w-50",style:([
                  link.collapseOpen === true
                    ? { transform: 'rotate(180deg)' }
                    : {} ]),attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","viewBox":"0 0 24 24","stroke-width":"3","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z"}}),_c('polyline',{attrs:{"points":"6 9 12 15 18 9"}})])])]),_c('Collapse',{ref:link.collapseId,refInFor:true,attrs:{"id":link.collapseId},on:{"open":function($event){return _vm.collapseLinkOpen(i, link.collapseId)},"close":function($event){return _vm.collapseLinkClose(i, link.collapseId)}}},_vm._l((link.children),function(child,ci){return _c('div',{key:child.path},[(child.children === undefined)?_c('div',{class:[
                  'ps-5 sidebar-link d-flex flex-row align-items-center w-100 h-100',
                  { active: _vm.$route.path === child.path } ],on:{"click":function($event){return _vm.navigateToPath(child.path, $event)}}},[(child.childIcon)?_c('svg',{staticClass:"stroke-default",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10","height":"10","viewBox":"0 0 24 24","stroke-width":"5","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"9"}})]):_vm._e(),_c('div',{staticClass:"ms-3"},[_vm._v(_vm._s(child.name))])]):_c('div',[_c('span',{directives:[{name:"collapse",rawName:"v-collapse",value:(child.collapseId),expression:"child.collapseId"}],class:[
                    'ps-5 sidebar-link d-flex flex-row align-items-center w-100 h-100',
                    { active: _vm.$route.path === child.path } ],on:{"click":function($event){return _vm.navigateToPath(child.path, $event)}}},[_c('div',{staticClass:"ms-3 d-flex align-items-center w-100"},[_c('div',{staticClass:"w-100"},[_vm._v(_vm._s(child.name))]),_c('svg',{staticClass:"stroke-default ms-2 w-50",style:([
                        child.collapseOpen === true
                          ? { transform: 'rotate(180deg)' }
                          : {} ]),attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","viewBox":"0 0 24 24","stroke-width":"3","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z"}}),_c('polyline',{attrs:{"points":"6 9 12 15 18 9"}})])])]),_c('Collapse',{attrs:{"id":child.collapseId},on:{"open":function($event){_vm.links[i].children[ci].collapseOpen = true},"close":function($event){_vm.links[i].children[ci].collapseOpen = false}}},_vm._l((child.children),function(childchild){return _c('div',{key:childchild.path},[_c('div',{class:[
                        'ps-5 sidebar-link d-flex flex-row align-items-center w-100 h-100',
                        { active: _vm.$route.path === childchild.path } ],on:{"click":function($event){return _vm.navigateToPath(childchild.path, $event)}}},[(childchild.childIcon)?_c('svg',{staticClass:"stroke-default w-50",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10","height":"10","viewBox":"0 0 24 24","stroke-width":"5","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"9"}})]):_vm._e(),_c('div',{staticClass:"ms-3"},[_vm._v(_vm._s(childchild.name))])])])}),0)],1)])}),0)],1)])}),0)]):_vm._e(),_c('div',{class:['app-container', { 'sidebar-open': !_vm.sidebarClosed }]},[_c('SidebarContentOverlay',{attrs:{"show":!_vm.sidebarClosed}}),_c('Navbar',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var toggleSidebar = ref.toggleSidebar;
return [_c('div',{staticClass:"d-flex align-items-center w-100"},[(_vm.isLogged)?_c('div',{staticClass:"cursor-pointer",on:{"click":toggleSidebar}},[_c('svg',{staticClass:"stroke-default",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"30","height":"30","viewBox":"0 0 24 24","stroke-width":"1.5","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z"}}),_c('line',{attrs:{"x1":"4","y1":"6","x2":"20","y2":"6"}}),_c('line',{attrs:{"x1":"4","y1":"12","x2":"20","y2":"12"}}),_c('line',{attrs:{"x1":"4","y1":"18","x2":"20","y2":"18"}})])]):_vm._e(),_c('div',{staticClass:"ms-auto d-flex flex-row justify-content-end align-items-center"},[_c('div',{staticClass:"ms-5"},[(_vm.isLogged)?_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center cursor-pointer"},[_c('button',{staticClass:"ms-3 btn btn-outline-default",on:{"click":_vm.logUserOut}},[_vm._v(" Выйти ")])]):_vm._e()])])])]}}])}),_c('div',{staticClass:"content-container container-fluid mt-4 mb-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[_c('router-view')],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }