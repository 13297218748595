<template>  
  <div>
    <div class="input-label mb-3">
      <span>Тип задания</span>
    </div>
    <Select
      class="mb-3"
      :data="taskTypes"
      @change="selectedTaskType = $event"
    >
      <template v-slot:placeholder="{ selectedItem }">
        <div
          v-if="selectedItem !== null && selectedItem !== undefined"
          class="d-flex flex-row align-items-center"
        >
          <div>{{ selectedItem.name }}</div>
        </div>
      </template>
      <template v-slot:default="{ item }">
        <div class="d-flex flex-row align-items-center text-">
          <div class="w-75">
            {{ item.name }}
          </div>
        </div>
      </template>
    </Select>

    <Input
      v-if="selectedTaskType"
      v-model="points"
      placeholder=""
      type="number"
      label="Очки к начислению"
      :invalid="validating ? points.length == 0 : null"
      :error-text="
        validating && points.length == 0 ? 'Поле не заполнено' : ''
      "
    />

    <Input
      v-if="
        selectedTaskType &&
          (isVideoTask(selectedTaskType.id) ||
            isLongreadTask(selectedTaskType.id))
      "
      v-model="url"
      placeholder=""
      label="Ссылка"
      :invalid="validating ? url.length == 0 : null"
      :error-text="
        validating && url.length == 0 ? 'Поле не заполнено' : ''
      "
    />

    <Input
      v-if="
        selectedTaskType &&
          (isVideoTask(selectedTaskType.id) ||
            isLongreadTask(selectedTaskType.id))
      "
      v-model="time_to_study"
      placeholder=""
      type="number"
      label="Время на изучение (минуты)"
      :invalid="validating ? time_to_study.length == 0 : null"
      :error-text="
        validating && time_to_study.length == 0 ? 'Поле не заполнено' : ''
      "
    />

    <div v-if="selectedTaskType && (isMainTask(selectedTaskType.id) || isAdditionalTask(selectedTaskType.id))">
      <div
        v-for="(quest, qindex) in questions"
        :key="qindex"
      >
        <div class="input-label mt-3 mb-1">
          <b v-if="isMainTask(selectedTaskType.id)">{{ +qindex + 1 }}) Вопрос:</b>
          <b v-else>Вопрос:</b>
        </div>

        <Input
          v-model="quest.qtext"
          tag="textarea"
          rows="6"
          class="mt-1 mb-1"
          :invalid="validating ? quest.qtext.length == 0 : null"
          :error-text="
            validating && quest.qtext.length == 0 ? 'Поле не заполнено' : ''
          "
        />

        <div>
          <div class="input-label">
            <label>Прикрепленные к вопросу изображения</label>
          </div>

          <Dropzone
            v-slot:default="{ files , removeFileByIndex, active, browse }"
            ref="dropzone"
            :multiple="true"
            class="mb-3"
            @change="encodeImages(quest, $event)"
          />
        </div>

        <div v-if="quest.images && quest.images.length > 0">
          <div class="mt-4">
            Добавленные файлы:
          </div>
          <div
            v-for="file in quest.images"
            :key="file._id"
          >
            <div class="input-label">
              <label>{{ file.name }}</label>
            </div>
            <img
              style="max-width: 100px;"
              :src="'data:' + file.contentType + ';base64,' + (file.src ? file.src : file.data)"
            >
          </div>
        </div>

        <div 
          class="input-label mb-3"
        >
          <span>Тип ответа на вопрос:</span>
        </div>
        <Select
          class="mb-3"
          :data="taskAnswerTypes"
          :invalid="validating ? getAnswerType(quest) === undefined : null"
          :error-text="
            validating && getAnswerType(quest) === undefined ? 'Поле не заполнено' : ''
          "
          @change="changeAnswerType(quest, $event)"
        >
          <template v-slot:placeholder="{ selectedItem }">
            <div
              v-if="selectedItem !== null && selectedItem !== undefined"
              class="d-flex flex-row align-items-center"
            >
              <div>{{ selectedItem.name }}</div>
            </div>
          </template>
          <template v-slot:default="{ item }">
            <div class="d-flex flex-row align-items-center text-">
              <div class="w-75">
                {{ item.name }}
              </div>
            </div>
          </template>
        </Select>

        <div v-if="quest.type !== undefined && quest.type !== null">
          <div class="input-label mt-2 mb-1">
            <b>Ответы:</b>
          </div>
          <div 
            :class="[
              {
                'input-container error border rounded border-danger p-2': validating && isTypeOne(quest) && (hasRightAnswer(quest) === false)
              }
            ]"
          >
            <div
              v-for="(answer, aindex) in quest.answer"
              :key="aindex"
            >
              <Input
                v-model="answer.atext"
                class="mb-1"
                :invalid="validating ? answer.atext.length == 0 : null"
                :error-text="
                  validating && answer.atext.length == 0
                    ? 'Поле не заполнено'
                    : ''
                "
              >
                <template v-slot:prepend>
                  <div
                    v-if="isTypeOne(quest)"
                    style="cursor: pointer;"
                    class="input-group-text"
                    :class="[{'bg-success': answer.isRight}]"
                    @click="changeAnswerRight(answer)"
                  >
                    <span :class="[{'text-light': answer.isRight}]">{{ aindex + 1 }}.</span>
                  </div>
                  <div
                    v-else-if="isTypeSequence(quest)"
                    class="input-group-text bg-light"
                  >
                    <span>{{ aindex + 1 }}.</span>
                  </div>
                </template>
              </Input>
            </div>
          </div>
        </div>

        <div 
          v-if="validating && isTypeOne(quest) && (hasRightAnswer(quest) === false)" 
          class="input-error"
        >
          <span>Не выбран правильный ответ</span>
        </div>

        <TagInput
          v-if="isTypeSequence(quest)"
          v-model="quest.right_answer_sequence"
          info-text="Впишите номер ответа и нажмите Enter"
          label="Последовательность правильных ответов"
          :invalid="validating ? (getSequenceValidateError(quest)).length > 0 : null"
          :error-text="validating ? getSequenceValidateError(quest) : ''"
        />
      </div>
    </div>

    <button
      ref="saveTask"
      class="btn btn-success mt-3"
      @click="saveTask"
    >
      Сохранить
    </button>
  </div>
</template>

<script>
import TaskService from "../../services/TaskService";
import {requiredValidate} from "../../helpers/helpers";
import swal from "sweetalert";
import { encodeImages, hasRightAnswer, isTypeOne, isTypeSequence, getSequenceValidateError } from '../../helpers/taskHelper';

function getAdditionalQuestionTemplate() {
  return [
    {
      qtext:'', 
      images: [],
      type: null,
      right_answer_sequence: [],
      answer: [
          {atext: ''},
          {atext: ''},
          {atext: ''},
          {atext: ''},
      ]
    }
  ];
}

function getMainQuestionTemplate() {
  return [
    {qtext:'', answer: [
        {atext: ''},
        {atext: ''},
        {atext: ''},
        {atext: ''},
    ]},
    {qtext:'', answer: [
        {atext: ''},
        {atext: ''},
        {atext: ''},
        {atext: ''},
    ]},
    {qtext:'', answer: [
        {atext: ''},
        {atext: ''},
        {atext: ''},
        {atext: ''},
    ]},
    {qtext:'', answer: [
        {atext: ''},
        {atext: ''},
        {atext: ''},
        {atext: ''},
    ]},
    {qtext:'', answer: [
        {atext: ''},
        {atext: ''},
        {atext: ''},
        {atext: ''},
    ]}
  ];
}

export default {
  name: "NewTask",

  props:{
    dayId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      validating: false,
      points: '',
      url:'',
      time_to_study: '',
      day_images: [],
      questions: [],
      selectedTaskType: null,
      taskTypes: TaskService.getTaskType(),
      taskAnswerTypes: TaskService.getTaskAnswerTypes()
    };
  },

  watch: {
    selectedTaskType: function(newType) {
      const vm = this;
      if (!newType) {return;}

      if (vm.isMainTask(newType.id)) {
        const newMainTemplate = getMainQuestionTemplate();
        vm.questions = Object.assign({}, newMainTemplate);
        vm.$forceUpdate();
        return;
      }

      if (vm.isAdditionalTask(newType.id)) {
        const newAddTemplate = getAdditionalQuestionTemplate();
        vm.questions = Object.assign({}, newAddTemplate);
        vm.$forceUpdate();
        return;
      }
    }
  },

  methods: {
    async saveTask() {
        let vm = this;
        if (this.selectedTaskType == null) {
            swal("Ошибка!", "Тип задания не выбран", "warning");
            return;
        }

        this.validating = true;
        await this.$nextTick();
        if (this.requiredValidate()) {
            return false;
        }

        const selectedTypeId = this.selectedTaskType.id;

        let data = {
            day_id: this.dayId,
            type: selectedTypeId,
            point: this.points
        };
        if (this.isVideoTask(selectedTypeId) || this.isLongreadTask(selectedTypeId)) {
            data.url = this.url;
            data.time_to_study = this.time_to_study;
        } else if (this.isAdditionalTask(selectedTypeId) || this.isMainTask(selectedTypeId)) {
            if (typeof this.questions === 'object') {
              data.question = Object.values(this.questions);
            } else {
              data.question = this.questions;
            }
        }

        TaskService.create(data).then(async (response) => {
            // await vm.clean();
            vm.$emit('change', response.data);
        }).catch(error => {
            console.log(error);
            let err_message = "Что то пошло не так";
            if (error.response && error.response.data.message) {
                err_message = error.response.data.message;
            }
            swal("Ошибка!", err_message, "error");
        });

        this.validating = false;
    },
    getAnswerType(question) {
      const type = this.taskAnswerTypes.find((task) => parseInt(task.id) === parseInt(question.type));
      return type;
    },
    changeAnswerRight(answer) {
      let isRight = answer.isRight ? !answer.isRight : true; 
      this.$set(answer, 'isRight', isRight);
    },
    changeAnswerType(question, event) {
      let vm = this;
      question.type = event.id;
      vm.$forceUpdate();
    },
    async clean()
    {
      this.url = '';
      this.time_to_study = '';
      this.questions = [];
      this.selectedTaskType = null;
      this.points = '';
      await this.$nextTick();
      this.$forceUpdate();
    },
    requiredValidate: function () {
      const elements = document.getElementsByClassName('input-container error');
      return requiredValidate(elements);
    },
    isAdditionalTask(value) {return TaskService.isAdditionalTask(value);},
    isMainTask(value) {return TaskService.isMainTask(value);},
    isVideoTask(value) {return TaskService.isVideoTask(value);},
    isLongreadTask(value) {return TaskService.isLongreadTask(value);},
    getSequenceValidateError(question) { return getSequenceValidateError(question); },
    hasRightAnswer(question) { return hasRightAnswer(question); },
    isTypeOne(question) { return isTypeOne(question); },
    isTypeSequence(question) { return isTypeSequence(question); },
    encodeImages(question, event) { encodeImages(question, event); },
  }
}
</script>