import TaskService from "../services/TaskService";
import { getBase64Url, arrayElementIsUnique } from "./helpers";

export const hasRightAnswer = (question) => {
  let foundRight = false;
  for (const answer of question.answer) {
    if (answer.isRight) {
      foundRight = true;
      break;
    }
  }
  return foundRight;
};

export const isTypeOne = (question) => {
  return question.type === TaskService.ANSWER_TYPE_ONE;
};

export const isTypeSequence = (question) => {
  return question.type === TaskService.ANSWER_TYPE_SEQUENCE;
};

export const encodeImages = (question, event) => {
  let vm = this;
  // const images = [];
  question.images = [];

  for (const image of event) {
    if (image instanceof File) {
      getBase64Url(image, function(base64Data){
          question.images.push({
            name: image.name,
            contentType: image.type,
            data: base64Data.split('base64,')[1]
          });
          vm.$forceUpdate();
          // question.images = question.images.concat(images);
      });
    }
  }
};

export const getSequenceValidateError = (question) => {
  if (question.right_answer_sequence.length === 0) {
    return 'Поле не заполнено';
  }

  if (question.right_answer_sequence.length !== TaskService.NUM_ANSWERS) {
    return 'Необходимое количество ответов : ' + TaskService.NUM_ANSWERS;
  }

  for (const answer_num of question.right_answer_sequence) {
    if (TaskService.ANSWER_SEQUENCE_NUMS.indexOf(parseInt(answer_num)) < 0) {
      return 'Неверно указаны варианты ответов, доступные варианты: ' + TaskService.ANSWER_SEQUENCE_NUMS.join(',');
    }
  }

  if (arrayElementIsUnique(question.right_answer_sequence) === false) {
    return 'Варианты ответов не должны повторяться';
  }

  return '';
};