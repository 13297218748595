<template>
  <div id="main-layout">
    <!-- Notification container -->
    <ToastNotificationsContainer />
    <!-- Sidebar -->
    <Sidebar v-if="isLogged">
      <div
        class="sidebar-brand d-flex justify-content-center align-items-center"
      >
        <b class="font-size-70 logo-text">Панель управления чат-бот Ученый</b>
      </div>

      <!-- Sidebar menu items -->
      <ul class="nav flex-column sidebar-items">
        <li
          :class="['nav-item', 'sidebar-item']"
          v-for="(link, i) in links"
          :key="i"
        >
          <!-- Simple link to route -->
          <span
            v-if="link.children === undefined"
            :class="[
              'sidebar-link d-flex flex-row align-items-center w-100 h-100',
              { active: $route.path === link.path },
            ]"
            @click="navigateToPath(link.path, $event)"
          >
            <div v-if="link.icon !== undefined" v-html="link.icon"></div>
            <div class="ms-3">{{ link.name }}</div>
          </span>

          <!-- Link with children links which will be placed in collapse -->
          <div v-else>
            <span
              :class="[
                'sidebar-link d-flex flex-row align-items-center w-100 h-100',
                { active: $route.path === link.path },
              ]"
              @click="navigateToPath(link.path, $event)"
              v-collapse="link.collapseId"
            >
              <div v-if="link.icon !== undefined" v-html="link.icon"></div>
              <div class="ms-3 d-flex align-items-center w-100">
                <div class="w-100">{{ link.name }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :style="[
                    link.collapseOpen === true
                      ? { transform: 'rotate(180deg)' }
                      : {},
                  ]"
                  class="stroke-default ms-2 w-50"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  stroke-width="3"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </div>
            </span>
            <Collapse
              :id="link.collapseId"
              :ref="link.collapseId"
              @open="collapseLinkOpen(i, link.collapseId)"
              @close="collapseLinkClose(i, link.collapseId)"
            >
              <div
                v-for="(child, ci) in link.children"
                :key="child.path"
              >
                <div
                  v-if="child.children === undefined"
                 :class="[
                    'ps-5 sidebar-link d-flex flex-row align-items-center w-100 h-100',
                    { active: $route.path === child.path },
                  ]"
                  @click="navigateToPath(child.path, $event)"
                >
                  <svg
                    v-if="child.childIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    class="stroke-default"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    stroke-width="5"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="9" />
                  </svg>

                  <div class="ms-3">{{ child.name }}</div>
                </div>

                <div v-else>
                  <span
                    v-collapse="child.collapseId"
                    :class="[
                      'ps-5 sidebar-link d-flex flex-row align-items-center w-100 h-100',
                      { active: $route.path === child.path },
                    ]"
                    @click="navigateToPath(child.path, $event)"
                  >
                    <div class="ms-3 d-flex align-items-center w-100">
                      <div class="w-100">{{ child.name }}</div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        :style="[
                          child.collapseOpen === true
                            ? { transform: 'rotate(180deg)' }
                            : {},
                        ]"
                        class="stroke-default ms-2 w-50"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        stroke-width="3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </div>
                  </span>
                  
                   <Collapse
                    :id="child.collapseId"
                    @open="links[i].children[ci].collapseOpen = true"
                    @close="links[i].children[ci].collapseOpen = false"
                  >
                    <div
                      v-for="childchild in child.children"
                      :key="childchild.path"
                    >
                      <div
                      :class="[
                          'ps-5 sidebar-link d-flex flex-row align-items-center w-100 h-100',
                          { active: $route.path === childchild.path },
                        ]"
                        @click="navigateToPath(childchild.path, $event)"
                      >
                        <svg
                          v-if="childchild.childIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          class="stroke-default w-50"
                          width="10"
                          height="10"
                          viewBox="0 0 24 24"
                          stroke-width="5"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="9" />
                        </svg>

                        <div class="ms-3">{{ childchild.name }}</div>
                      </div>
                    </div>
                  </Collapse>
                </div>
                  
              </div>
            </Collapse>
          </div>
        </li>
      </ul>
    </Sidebar>
    <div :class="['app-container', { 'sidebar-open': !sidebarClosed }]">
      <!-- This will be shown only on mobile as black transparent clickable content overlay. See src/assets/scss/core/_sidebar.scss -->
      <SidebarContentOverlay :show="!sidebarClosed"></SidebarContentOverlay>
      <!--Navigation bar-->
      <Navbar>
        <template v-slot:default="{ toggleSidebar }">
          <div class="d-flex align-items-center w-100">
            <!--Hamburger menu-->
            <div v-if="isLogged" @click="toggleSidebar" class="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="stroke-default"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="4" y1="6" x2="20" y2="6" />
                <line x1="4" y1="12" x2="20" y2="12" />
                <line x1="4" y1="18" x2="20" y2="18" />
              </svg>
            </div>

            <div
              class="ms-auto d-flex flex-row justify-content-end align-items-center"
            >
              <div class="ms-5">
                <div
                  v-if="isLogged"
                  class="d-flex flex-row align-items-center justify-content-center cursor-pointer"
                >
                  <button
                    class="ms-3 btn btn-outline-default"
                    @click="logUserOut"
                  >
                    Выйти
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Navbar>
      <!-- Content with router view -->
      <div class="content-container container-fluid mt-4 mb-5">
        <div class="row">
          <div class="col">
            <transition mode="out-in" name="fade">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icons from "./../helpers/icons";
import { generateKey } from "./../helpers/helpers";
import CourseService from "./../services/CourseService";
import DayService from "./../services/DayService";

export default {
  name: "app",

  data() {
    return {
      icons,
      // State of sidebar
      sidebarClosed: true,
      links: [
        {
          name: "Пользователи",
          path: "/",
          icon: icons.UserTable
        },
        {
          name: "Обр. дни",
          path: "/days",
          icon: icons.Day,
        },
      ],
    };
  },

  async mounted() {
    const courses = await CourseService.getAll('_id, name');
    const days = await DayService.getAll('_id, name, course_id');
    const courseLinks = [];

    for (const course of courses) {
      const courseLink = {
        "name": course.name,
         collapseId: generateKey(),
         collapseOpen: false,
         path: "/courses/" + course._id,
         childIcon: false
      };
      const courseChildren = [];
      const courseDays = days.filter((day) => day.course_id == course._id);

      for (const day of courseDays) {
        courseChildren.push({
          name: day.name,
          path: "/days/" + day._id
        })
      }

      courseLink['children'] = courseChildren;
      courseLinks.push(courseLink);
    }

    this.links.push({
      name: "Курсы",
      icon: icons.Course,
      collapseId: generateKey(),
      collapseOpen: false,
      path: "/courses",
      children: courseLinks
    });

    this.$nextTick();
    this.$forceUpdate();
  },

  methods: {
    navigateToPath(path, e) {
      e.stopPropagation();

      // Close sidebar on mobile devices on sidebar link click
      if (window.innerWidth < 768) {
        this.$sidebar.close();
      }

      this.$router.push({ path });
    },
    getUserDetails() {
      // let token = localStorage.getItem("jwt");
      // this.user = token && VueJwtDecode.decode(token);
      // this.isLogged = token !== null;
      this.sidebarClosed = !this.isLogged;

      if (!this.isLogged) {
        document.title = "Авторизация";
      } else {
        document.title = "Панель управления чат-бот Ученый";
      }
    },
    logUserOut() {
      localStorage.removeItem("jwt");
      this.$router.push("/login");
    },
    collapseLinkOpen(i, id) {
      this.links[i].collapseOpen = true;
    },
    collapseLinkClose(i, id) {
      this.links[i].collapseOpen = false;
      const link = this.links[i];
      const linkBtn = this.$refs[id][0];
      let foundSelected = false;

      for (const child of link.children) {
        if (foundSelected) {break;}

        if (child.collapseOpen) {
          foundSelected = true;
          break;
        }

        for (const childchild of child.children) {
          if (childchild.collapseOpen) {
            foundSelected = true;
            break;
          }
        }
      }

      if (foundSelected) {
        this.links[i].collapseOpen = true;
        linkBtn.open();
      }
    }
  },

  watch: {
    // Watch if sidebar is being closed/opened and adjust visibility of overlay, content
    "$sidebar.sidebarVisible": function (val) {
      this.sidebarClosed = !val;
    },
  },
  computed: {
    token() {
      return localStorage.getItem("jwt");
    },
    isLogged() {
      return localStorage.getItem("jwt") !== null;
    },
  },

  created() {
    this.getUserDetails();
  },
};
</script>