import Vue from 'vue';

class DayService {
  constructor()
  {
    this.STATUS_ACTIVE = 0;
    this.STATUS_ARCHIVE = 1;
    this.status_names = {};
    this.status_names[this.STATUS_ACTIVE] = 'Активный';
    this.status_names[this.STATUS_ARCHIVE] = 'Архив';
  }

  getStatuses() {
    const statuses = [];

    for (const key in this.status_names) {
      statuses.push({
        id: key,
        name: this.status_names[key]
      });
    }

    return statuses;
  }
  
  getStatusName(status) {
    return this.status_names[status];
  }

  async getAll(fields=null) {
    try {
        let url = "/day?";
        if (fields) {
          url += 'fields=' + fields;
        }
        return (await Vue.prototype.$http.get(url)).data;
    } catch (error) {
        console.error(error);
        return [];
    }
  }
  
  async get(id) {
    try {
      return (await Vue.prototype.$http.get(`/day/${id}`)).data;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  create(data) {
    return Vue.prototype.$http.post("/day", data);
  }

  update(id, data) {
    return Vue.prototype.$http.put(`/day/${id}`, data);
  }
}
  
  export default new DayService();